import React, {useCallback} from 'react';
import {Box, Link, Stack, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {useTranslation} from "react-i18next";
import {AppRoutes} from "../router";
import {useNavigate} from "react-router-dom";
import configuration from "../configuration";

function Footer() {
  const {t} = useTranslation('footer');
  const navigate = useNavigate();

  function goTo(href: string) {
    return () => {
      navigate(href);
      window.scrollTo({
        top: 0,
      });
    }
  }

  return (
    <Box sx={{background: '#00000040'}} py={5} px={{xs: 0, sm: 5}}>
      <Grid2 container rowSpacing={4}>

        <Grid2 xs={12} md={5}>
          <Typography mb={2} fontSize={{xs: 20, sm: 27}} textAlign={{xs: 'center', sm: 'start'}}
                      sx={{wordBreak: 'break-word'}}>logisticmobileapplication@gmail.com</Typography>
          <Stack direction={{xs: 'column', sm: 'row'}} spacing={{xs: 0, sm: 4}}
                 sx={{justifyContent: {xs: 'center', sm: 'flex-start'}}}>
            <Link onClick={goTo(AppRoutes.PrivacyPolicy)} underline="hover" variant='subtitle1'
                  textAlign={{xs: 'center', sm: 'start'}}>
              {t('privacyPolicy')}
            </Link>
            <Link onClick={goTo(AppRoutes.Terms)} underline="hover" variant='subtitle1'
                  textAlign={{xs: 'center', sm: 'start'}}>
              {t('conditions')}
            </Link>
          </Stack>
        </Grid2>

        <Grid2 xs={12} md={2}>
          <Stack direction='row' height='100%' justifyContent='center' alignItems='flex-end'>
            <Box sx={{maxHeight: {xs: 100, md: 100}}}
                 component='img' src='/icon/logo.svg' alt='logo logistic'/>
          </Stack>
        </Grid2>

        <Grid2 xs={12} md={5}>
          <Stack direction={{xs: 'column', sm: 'row'}} sx={{justifyContent: {xs: 'space-between', sm: 'flex-end'}}}
                 alignItems='center'
                 spacing={4}>
            <Typography fontSize={27} sx={{textAlign: {xs: 'center', md: 'end'}}}>
              {t('whereWe')}
            </Typography>

            <Stack direction='row' spacing={4}>
              <Link href={configuration.facebookLink} target='_blank'>
                <img height={35} width={35} src='/icon/facebook.png' alt='facebook icon'/>
              </Link>
              <Link href={configuration.instagramLink} target='_blank'>
                <img height={35} width={35} src='/icon/instagram.png' alt='instagram icon'/>
              </Link>
            </Stack>

          </Stack>
          <Typography mt={2} sx={{textAlign: {xs: 'center', sm: 'end'}}}>© 2024 Swiftcode private limited
            company.</Typography>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default Footer;
