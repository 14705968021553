import {createTheme} from '@mui/material/styles';
import {red} from '@mui/material/colors';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#00BFFE',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: '#00BFFE'
    }
  },
  typography: {
    fontFamily: '"Jura", sans-serif',
    h2: {
      color: '#fff',
      fontSize: 'clamp(40px, calc(40px + 16 * (100vw / 1280)), 64px)',
      fontWeight: 700,
    },
    h4: {
      color: '#00BFFE',
      fontSize: 'clamp(26px, calc(26px + 16 * (100vw / 1280)), 36px)',
    },
    h5: {
      color: '#fff'
    },
    h6: {
      color: '#fff',
      fontSize: 'clamp(16px, calc(16px + 16 * (100vw / 1280)), 24px)',
      fontWeight: 500
    },
    subtitle1: {
      fontSize: 'clamp(18px, calc(16px + 16 * (100vw / 1280)), 20px)',
      fontWeight: 400
    }
  }
});

export default theme;
