import React, {useCallback, useState} from 'react';
import {Box, Button, Collapse, Stack} from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {useTranslation} from "react-i18next";
import {checkLanguage, Language} from "language";
import {TitleLink} from 'components/RenderSuggestion';

export interface NavigateMenuProps {
  py?: number | undefined;
  mt?: number;
}

function NavigateMenu({py, mt}: NavigateMenuProps) {
  const {t, i18n} = useTranslation('navigateMenu');
  const languages: Language[] = [Language.En, Language.Ua];

  const [lng, setLng] = useState<Language>(
    checkLanguage(i18n.language) as Language);
  const [open, setOpen] = useState<boolean>(false);

  const changeLanguage = useCallback(() => {
    const currentIndex = languages.indexOf(lng);
    const nextIndex = (currentIndex + 1) % languages.length;
    const nextLanguage = languages[nextIndex];
    setLng(nextLanguage);
    i18n.changeLanguage(nextLanguage)
    localStorage.setItem("lng", nextLanguage)
  }, [lng]);

  function scrollTo(id: string) {
    return () => {
      let target = id.slice(1);
      document.getElementById(target)?.scrollIntoView({behavior: 'smooth'});
    }
  }

  const textSize = 'clamp(18px, calc(18px + 16 * (100vw / 1280)), 27px)';

  const toggleOpen = useCallback(() => {
    setOpen(prevState => !prevState);
  }, [open]);

  function RenderMenu() {
    return (
      <Stack direction={{xs: 'column', md: 'row'}} justifyContent='space-evenly' flexWrap='wrap'>
        <Button sx={{textTransform: 'none', fontSize: textSize}} href={TitleLink.OrderServices}>
          {t('order')}
        </Button>
        <Button sx={{textTransform: 'none', fontSize: textSize}} href={TitleLink.ProvideServices}>
          {t('provide')}
        </Button>
        <Button sx={{textTransform: 'none', fontSize: textSize}}
                href={TitleLink.DownloadMobile}>{t('downloadApplication')}
        </Button>
        <Button sx={{textTransform: 'none', fontSize: textSize}}
                href={TitleLink.AboutLogistics}>{t('aboutLogistic')}
        </Button>
        <Button sx={{textTransform: 'uppercase', fontSize: textSize}} onClick={changeLanguage}>
          {lng}
        </Button>
      </Stack>
    )
  }

  return (
    <Box py={py} mt={mt}>
      <Box display={{xs: 'block', md: 'none'}}>
        <Stack alignItems='center' justifyContent='center' direction='row'>
          <Button endIcon={open ? <ExpandLess/> : <ExpandMore/>}
                  sx={{textTransform: 'none', fontSize: textSize}}
                  onClick={toggleOpen}>{t('menu')}</Button>
        </Stack>
        <Collapse in={open}>
          {RenderMenu()}
        </Collapse>
      </Box>

      <Box display={{xs: 'none', md: 'block'}}>
        {RenderMenu()}
      </Box>
    </Box>
  );
}


export default NavigateMenu;
