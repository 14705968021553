import React from 'react';
import {Container, Stack, Typography} from "@mui/material";

function NotFoundPage() {
  return (

    <Stack flexGrow={1} direction='column' justifyContent='center' alignItems='center' height='100%'>
      <Typography variant='h2' textAlign='center'>Not found page 404</Typography>
    </Stack>

  );
}

export default NotFoundPage;
