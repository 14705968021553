import React, {useCallback} from 'react';
import {Box, Container, Link, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {AppRoutes} from "router";
import {useNavigate} from 'react-router-dom';


function Header() {
  const {t} = useTranslation(['transaction'])
  const navigate = useNavigate();

  const goToHome = useCallback(() => {
    navigate(AppRoutes.Home);
  }, [])

  return (
    <Box component='header' sx={{borderBottom: '2px solid #00BFFE'}}>
      <Container>
        <Stack py={1}>

          <Link underline='none' variant='h4' my={3}
                onClick={goToHome}
                textAlign='center'>
            {t("siteName")}
          </Link>

        </Stack>
      </Container>
    </Box>
  )
    ;
}

export default Header;
