import React, {ReactNode} from 'react';
import Header from "./Header";
import {Stack} from "@mui/material";
import Footer from "./Footer";

export interface MainProps {
  children: ReactNode
}

function Main({children}: MainProps) {

  return (
    <>
      <Header/>
      <Stack component='main' sx={{minHeight: '100dvh'}} direction='column'>
        {children}
      </Stack>
      <Footer/>
    </>
  );
}

export default Main;
