export enum Language {
  En = 'en',
  Ua = 'ua'
}

export const languageList = [Language.En, Language.Ua]

export function checkLanguage(lng: string) {
  const sliceLng = lng.slice(0, 2);

  if (sliceLng === 'uk' || sliceLng === 'ru') {
    return Language.Ua;
  }

  return languageList.includes(sliceLng as Language) ? sliceLng : Language.En;
}
