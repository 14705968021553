import React from 'react';
import NavigateMenu from "components/NavigateMenu";
import {Box, Container} from "@mui/material";
import RenderSuggestion from "components/RenderSuggestion";

function HomePage() {
  return (
    <Container>
      <NavigateMenu mt={4}/>
      <Box py={10} pt={{xs: 3, md: 10}}>
        <RenderSuggestion/>
      </Box>

    </Container>
  );
}

export default HomePage;
