import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'
import App from './App';
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from 'theme';

import './i18n'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <CssBaseline/>
        <App/>
      </React.StrictMode>
    </ThemeProvider>
  </React.StrictMode>
);
