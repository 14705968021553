import React from 'react';
import {Container, Typography} from "@mui/material";

function PrivacyPolicyPage() {
  return (
    <Container>
      <Typography variant='h2' textAlign='center' sx={{wordBreak: 'break-word'}}>PrivacyPolicyPage</Typography>
    </Container>
  );
}

export default PrivacyPolicyPage;
