import React from "react";
import {createBrowserRouter, Outlet} from "react-router-dom";

import Main from "./layout";
import HomePage from "page/HomePage";
import PrivacyPolicyPage from "page/PrivacyPolicyPage";
import TermsPage from "page/TermsPage";
import NotFoundPage from "page/NotFoundPage";


export enum AppRoutes {
  Home = '/',
  PrivacyPolicy = '/privacy-policy',
  Terms = '/terms',
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main> <Outlet/> </Main>,
    children: [
      {
        path: AppRoutes.Home,
        element: <HomePage/>,
      },
      {
        path: AppRoutes.PrivacyPolicy,
        element: <PrivacyPolicyPage/>,
      },
      {
        path: AppRoutes.Terms,
        element: <TermsPage/>,
      },
      {
        path: '*',
        element: <NotFoundPage/>
      }
    ]
  },
]);

export default router;
