import React from 'react';
import {Container, Typography} from "@mui/material";

function TermsPage() {
  return (
    <Container>
      <Typography variant='h2' textAlign='center'>TermsPage</Typography>
    </Container>
  );
}

export default TermsPage;
