import React, {useEffect} from "react";
import {RouterProvider} from "react-router-dom";
import router from "router";
import {useTranslation} from "react-i18next";
import {Language, checkLanguage} from "./language";

function App() {
  const {i18n} = useTranslation()

  useEffect(() => {
    const lng = localStorage.getItem('lng') || '';
    i18n.changeLanguage(lng).catch(reason => {
      console.log("Error changeLanguage", reason)
    });

    const checkLng = checkLanguage(i18n.language)
    if (!lng && checkLng) {
      i18n.changeLanguage(checkLng)
    }

  }, []);

  return (
    <RouterProvider router={router}/>
  );
}

export default App;
