import React, {ReactNode, useState} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {Box, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

export enum TitleLink {
  OrderServices = '#orderServices',
  ProvideServices = '#provideServices',
  DownloadMobile = '#downloadMobile',
  AboutLogistics = '#aboutLogistics'
}

export interface RenderSuggestionProps {
}

function RenderSuggestion() {
  const {t, i18n} = useTranslation('suggestion');
  const [render] = useState<Suggestion[]>([
    {
      type: {title: 'orderServices',},
      id: 'orderServices'
    },
    {
      type: {description: 'orderServicesDescription'}
    },
    {
      type: {title: 'provideServices',},
      id: 'provideServices'
    },
    {
      type: {description: 'provideServicesDescription'}
    },
    {
      type: {title: 'downloadMobile'},
      id: 'downloadMobile'
    },
    {
      type: {value: RenderQr()}
    },
    {
      type: {title: 'aboutLogistics',},
      id: 'aboutLogistics'
    },
    {
      type: {description: 'aboutLogisticsDescription'}
    },
  ]);

  function renderSuggestion(suggestion: Suggestion): ReactNode {
    if (isSuggestionNode(suggestion.type)) {
      return suggestion.type.value;
    } else if (isSuggestionDescription(suggestion.type)) {
      // sx={{fontSize: {xs: 16, md: 18, lg: 24}}}
      return (<Typography variant='h6' my='auto'>
        {t(suggestion.type.description)}
      </Typography>);
    } else if (isSuggestionTitle(suggestion.type)) {
      return (
        // sx={{fontSize: {xs: 40, sm: 48, md: 54, lg: 64}}}
        <Typography variant='h2' my='auto' textTransform='uppercase'>
          {t(suggestion.type.title)}
        </Typography>);
    }

    return <Typography>None</Typography>;
  }

  return (
    <Grid2 container spacing={2}>
      {
        render.map((value, index) => (
          <Grid2 key={index}
                 id={value.id}
                 minHeight={430} xs={12} md={6}>
            <Stack sx={{background: '#00000040'}} height='100%' px={3} py={2}>
              {renderSuggestion(value)}
            </Stack>
          </Grid2>
        ))
      }
    </Grid2>
  );
}

function RenderQr() {
  return (
    <Stack direction='row' flexWrap='wrap' rowGap={3} columnGap={1} justifyContent='space-evenly'
           alignItems='center' flexGrow={1}>
      <Stack alignItems='center'>
        <Box height={180} sx={{aspectRatio: '1/1'}} mb={4} component='img' src='/icon/qrTemplate.png'
             alt='qr code app store'/>

        <Box height={40} width='121' component='img' src='/icon/appStore.png' alt='icon app store'/>
      </Stack>

      <Stack alignItems='center'>
        <Box height={180} sx={{aspectRatio: '1/1'}} mb={4} component='img' src='/icon/qrTemplate.png'
             alt='qr code google play'/>

        <Box height={40} component='img' width='136' src='/icon/googlePlay.png' alt='icon google play'/>
      </Stack>
    </Stack>
  );
}

// Type guards
function isSuggestionNode(type: any): type is SuggestionNode {
  return (type as SuggestionNode).value !== undefined;
}

function isSuggestionDescription(type: any): type is SuggestionDescription {
  return (type as SuggestionDescription).description !== undefined;
}

function isSuggestionTitle(type: any): type is SuggestionTitle {
  return (type as SuggestionTitle).title !== undefined;
}

type SuggestionNode = {
  value: ReactNode;
}

type SuggestionDescription = {
  description: string;
}

type SuggestionTitle = {
  title: string;

}

type Suggestion = {
  id?: string;
  type: SuggestionTitle | SuggestionDescription | SuggestionNode,
};

export default RenderSuggestion;
